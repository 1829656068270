import {
  Component, OnInit, ViewChild,
  ElementRef, AfterViewInit, Renderer2, Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { ApiService } from '../../services/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { CarouselComponent } from 'angular-responsive-carousel';
declare var $: any;

@Component({
  selector: 'app-apac-offers',
  templateUrl: './apac-offers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./apac-offers.component.css'],
  styles: [`
      ::ng-deep .offer-gallery .carousel-arrow-next{
          right: -20px !important;
      }

      ::ng-deep .offer-gallery .carousel-arrow-prev{
          left: -20px !important;
      }
  `]
})
export class ApacOffersComponent implements OnInit, AfterViewInit {

  @ViewChild("offerGallery") offerGallery!: ElementRef;
  @ViewChild('carouselCell', { static: false }) carouselCell!: ElementRef;
  iframeHeight: string = '100%';

  carouselTile: any = {
    grid: { xs: 3, sm: 4, md: 5, lg: 6 },
    slide: 1,
    load: 1
  };

  thumbnailTile: any = {
    grid: { xs: 3, sm: 4, md: 5, lg: 6 },
    slide: 1,
    load: 5
  };

  currentIndex: number = 0;
  currentThumbnailIndex = 0;
  thumbnailsToShow = 5; // Number of thumbnails to show
  bannersLoaded = 0;
  images: any[] = [
    {
      "src": "https://www.youtube.com/embed/WepYhlI0nns?si=eWVPupwjMvzXjIgM",
      "type": "video",
      "thumbnail": "https://d3cqf5kijn47yv.cloudfront.net/offer-banners/AI%20PC%20from%20Lenovo%2021-10-2024%2019_22_11_831%201.jpg"
    },
    {
      "src": "https://lenovo-apac.s3.ap-south-1.amazonaws.com/offer-banners/4329.jpg",
      "type": "img"
    },
    {
      "src": "https://lenovo-apac.s3.ap-south-1.amazonaws.com/offer-banners/Legion%205i%20Pro_1284x896_300%20Dpi.png",
      "type": "img"
    }
  ]
  visibleThumbnails: any[] = [];
  currentBanner: any;
  @Input('height') height: number = 250;
  @Input('width') width: number = 400;
  @ViewChild("carouselTemplate") carouselTemplate?: CarouselComponent
  bannerImages: any[] = [];
  slider: any;
  player: any;
  constructor(private apiService: ApiService,
    private ref: ChangeDetectorRef,

    private renderer: Renderer2, private domSanitizer: DomSanitizer, public utily: UtilitiesService
  ) { }


  pauseOnHover: boolean = true;
  interval: any; //ms 

  ngAfterViewInit() {
    this.ref.detach();
    this.pauseOnHover = window.innerWidth < 768 ? false : true;
    this.handleSilder();
  }

  handleSilder() {
    setInterval(() => {
      let slider: number = this.getCurrentSlide();
      let imageLength = this.images.length - 1;
      if (slider == imageLength) {
        if (!this.isPlaying) this.carouselTemplate?.carousel.prev(slider);
      } else {
        if (!this.isPlaying) this.carouselTemplate?.carousel.next(slider + 1);
      }
    }, this.interval)
  }

  onIframeLoad(event: any) {
    const carouselCellHeight = this.carouselCell.nativeElement.offsetHeight;
    this.iframeHeight = `${carouselCellHeight}px`;
    let iframe = event.target as HTMLIFrameElement;
    if (iframe != null)
      iframe.style.height = this.iframeHeight;
  }


  ngOnInit(): void {
    this.caroselRender(this.images);
    this.initializeThumbnails();
    this.onThumbnailClick(this.currentIndex);
    this.startAutoCarousel();
    this.ref.detectChanges();
    this.utily.banners.subscribe(val => {
      this.images = val;
      // $('#thumb-list_holder').html('');
      this.caroselRender(this.images);
      this.onThumbnailClick(this.currentIndex);
      this.initializeThumbnails();
      this.startAutoCarousel();
      this.ref.detectChanges();

    })

  }

  autoPlay = true;
  isPlaying = false;
  playVideo() {
    if (!this.isPlaying) {
      var videoUrl = $(".iframe").prop('src');
      videoUrl += "&autoplay=1";
      $(".iframe").prop('src', videoUrl);
      // $(".newDivOverlay").css("z-index",-1);
      this.isPlaying = true;
      this.autoPlay = false;
      this.stopAutoCarousel();
    } else {
      var videoUrl = $(".iframe").prop('src');
      videoUrl = videoUrl.replace("&autoplay=1", "");
      $(".iframe").prop('src', '');
      $(".iframe").prop('src', videoUrl);
      this.autoPlay = false;
      this.isPlaying = false;
      this.startAutoCarousel();
    }

  }

  caroselRender(images: any) {
    let counter = 0;
    this.bannerImages = [];
    for (let img of images) {
      this.bannerImages.push({ path: img.src, alt: img.image_alt_text, type: img.type ,thumbnail: img.thumbnail });
    }
  }

  canShowImage(item: any) {
    return item && item.path && item.type == 'img';
  }

  canShowVideo(item: any) {
    return item && item.path && item.type == 'video';
  }

  transform(url: any) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  showPage(ev: any) {
    if ($(ev.target).hasClass('carousel-arrow')) {
      return;
    }
    const url = this.images[this.getCurrentSlide()].url;
    if (url) {
      window.open(url, '_blank');
    }
  }



  getCurrentSlide(): number {
    return this.carouselTemplate?.slideCounter;
  }

  nextSlide() {
    this.carouselTemplate?.next();
  }

  prevSlide() {
    this.carouselTemplate?.carousel.prev(0);
  }

  private initializeThumbnails() {
    this.visibleThumbnails = this.bannerImages.slice(this.currentThumbnailIndex, this.currentThumbnailIndex + this.thumbnailsToShow);
  }

  onThumbnailClick(index: number) {
    this.currentIndex = this.currentThumbnailIndex + index;
    this.currentBanner = this.bannerImages[this.currentIndex];
    this.startAutoCarousel();
    this.ref.detectChanges();
  }


  nextMainBanner() {
    if (this.currentIndex < this.bannerImages.length - 1) {
      this.currentIndex++;
      this.currentBanner = this.bannerImages[this.currentIndex];

      if (this.currentIndex >= this.currentThumbnailIndex + this.thumbnailsToShow) {
        const remainingThumbnails = this.bannerImages.length - this.currentThumbnailIndex - this.thumbnailsToShow;
        const shiftAmount = Math.min(remainingThumbnails, this.thumbnailsToShow);

        this.currentThumbnailIndex += shiftAmount;
        this.updateVisibleThumbnails();
      }
      this.startAutoCarousel();
      this.ref.detectChanges();
    }
  }

  prevMainBanner() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.currentBanner = this.bannerImages[this.currentIndex];

      if (this.currentIndex < this.currentThumbnailIndex) {
        this.currentThumbnailIndex = Math.max(0, this.currentThumbnailIndex - this.thumbnailsToShow);
        this.updateVisibleThumbnails();
      }
      this.startAutoCarousel();
      this.ref.detectChanges();
    }
  }


  nextThumbnails() {
    const total = this.bannerImages.length;
    const remaining = total - this.currentThumbnailIndex;

    const shift = Math.min(this.thumbnailsToShow, remaining);

    this.currentThumbnailIndex += shift;

    if (this.currentThumbnailIndex > total - this.thumbnailsToShow) {
      this.currentThumbnailIndex = total - this.thumbnailsToShow;
    }
    this.updateVisibleThumbnails();
    this.ref.detectChanges();
  }

  prevThumbnails() {
    const shift = Math.min(this.thumbnailsToShow, this.currentThumbnailIndex);

    this.currentThumbnailIndex -= shift;

    if (this.currentThumbnailIndex < 0) {
      this.currentThumbnailIndex = 0;
    }

    this.updateVisibleThumbnails();
    this.ref.detectChanges();
  }

  private updateVisibleThumbnails() {
    this.visibleThumbnails = this.bannerImages.slice(this.currentThumbnailIndex, this.currentThumbnailIndex + this.thumbnailsToShow);

    // if (this.visibleThumbnails.length < this.thumbnailsToShow) {
    //   const placeholders = Array(this.thumbnailsToShow - this.visibleThumbnails.length).fill({ type: 'empty' });
    //   this.visibleThumbnails = [...this.visibleThumbnails, ...placeholders];
    // }
  }

  ngOnDestroy() {
    this.stopAutoCarousel();
  }

  startAutoCarousel() {
    if (this.interval) {
      clearTimeout(this.interval);
    }

    const carouselSwitch = () => {
      this.currentIndex = (this.currentIndex + 1) % this.bannerImages.length;
      this.currentBanner = this.bannerImages[this.currentIndex];

      if (this.currentIndex >= this.currentThumbnailIndex + this.thumbnailsToShow) {
        const remainingThumbnails = this.bannerImages.length - this.currentThumbnailIndex - this.thumbnailsToShow;
        const shiftAmount = Math.min(remainingThumbnails, this.thumbnailsToShow);
        this.currentThumbnailIndex += shiftAmount;

        if (this.currentThumbnailIndex >= this.bannerImages.length) {
          this.currentThumbnailIndex = 0;
        }
        this.updateVisibleThumbnails();
      } else if (this.currentIndex === 0) {
        this.currentThumbnailIndex = 0;
        this.updateVisibleThumbnails();
      }

      this.ref.detectChanges();

      this.interval = setTimeout(carouselSwitch, 3000);
    };

    this.interval = setTimeout(carouselSwitch, 3000);
  }



  stopAutoCarousel() {
    clearTimeout(this.interval);
  }

}